import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plyo Pushups 4×6 `}<em parentName="p">{`(Pushup on floor, push as fast as possible to land on
plates with elbows extended)`}</em></p>
    <p>{`CTB Pullups 4×6 `}<em parentName="p">{`(Touch chest to bar as fast and as high as possible)`}</em></p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`8-Bench Press (135/85)(RX+ 165/105)`}</p>
    <p>{`8-Right Arm Renegade Rows (35/25)(RX+ 50/35)`}</p>
    <p>{`8-Left Arm Renegade Rows`}</p>
    <p>{`24-Situps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      